import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { BranchesService } from "src/core/services/branches/branches.service";
import { OrdersAdminService } from "src/core/services/orders-admin/orders-admin.service";
import { OrdersHelper } from "src/app/main/orders/classes/orders-helper";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { OrdersService } from "src/core/services/orders/orders.service";
import { PaymentsService } from "src/core/services/payments/payments.service";
import * as moment from "moment";
import { DropdownService } from "src/core/services/dropdown/dropdown.service";
import { UsersService } from "src/core/services/users/users.service";
import { AddQuery } from "src/app/main/reports/ItemStore/queries/queries";
import { ItemStoreService } from "src/core/services/ItemStore/Store.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { gql } from "apollo-angular";
import { NotificationsService } from "src/core/services/notifications/notifications.service";
import { pairwise, startWith } from "rxjs/operators";
import { TownsService } from "src/core/services/towns/towns.service";
import { Iinputs } from "src/core/shared/generic-filter/Interfaces/inputInterface";
import { ORDERSdATA } from "../../../classes/orders.model";
import { DeliversService } from "src/core/services/delivers/delivers.service";
import { PlatformsService } from "src/core/services/platforms/platforms.service";
import { UserGroup } from "src/core/enums/user.group.enum";
import { OrderStatusService } from "src/core/services/order-status/order-status.service";
@Component({
  selector: "app-order-admin-index",
  templateUrl: "./order-admin-index.component.html",
  styleUrls: ["./order-admin-index.component.css"],
})
export class OrderAdminIndexComponent implements OnInit {
  filterData: any = "";
  filterSub: Subscription;
  data: any = { total: 0, results: [] };
  temp: any[] = [];
  page: number = 1;
  limit: number = 5;
  Mobileheads = [
    // "#",
    "رقم الاوردر",
    "اسم المستخدم",
    "الاسم",
    "التلفون",
    "العنوان",

    "المنطقة",
    "الموصل",

    "الاجمالي",
    "ملاحظات",
    "تغيير حالة الطلب",
    "العملية",
  ];
  heads = [
    // "#",
    "رقم الاوردر",
    "البلاتفورم",
    "اسم المستخدم",
    "الاسم",
    "التلفون",
    "العنوان",
    "المنطقة",
    "الفرع",
    "نوع الطلب",
    "الحالة",
    // "الويتر",
    // "الطاولة",
    "الموصل",
    "تعليق الموصل",
    "وسيلة الدفع",
    "عدد الأطباق",
    "الحساب الجزئي",
    "خصم المحفظة",
    "خصم العروض",
    "خصم كود الخصم",
    "الضريبة",
    "خدمة التوصيل",
    "الاجمالي",
    "ملاحظات",
    "سبب الإلغاء",
    "تاريخ الطلب",
    "تغيير حالة الطلب",
    "العملية",
  ];
  loading: boolean = false;
  total: number = 0;
  cancel: number = 0;
  profit: number = 0;
  commission: number = 0;
  billingData: any = {};
  isBillingVisible: boolean = false;
  index: number;
  OrderId;
  queryParams: any;
  details: any = [];
  isVisible: boolean = false;
  drivers: any = [];
  popupHeader: any[] = [
    "#",
    "الطبق",
    "الكمية",
    "السعر",
    "الملاحظات",
    "الخيارات",
  ];
  RestaurantId: any = localStorage.getItem("RestaurantId");
  users: Object;
  filterVal: any;
  // filter
  status: any = [];
  payments: any;
  areas: any;
  restaurants: any;
  branches: any;
  branch_id: number;
  filterData2: any;

  orderStatusTypes: [] = [];
  order_status: [] = [];

  deliveries: any;
  selectedDelivery: any;
  selectdeliveryForOrderModal: boolean = false;
  selectedOrder: any;

  editOrderVisiblity: boolean = false;
  usersSuggestions: any[];

  name: any;
  timer: NodeJS.Timeout;
  Delivers: any;
  delivery_id: number;
  userGroup = UserGroup;

  filterInputs: Iinputs[] = [
    {
      type: "number",
      name: "id",
      placeholder: "رقم الاوردر",
    },
    {
      type: "search",
      name: "billing_address_id",
      field: "first_name",
      placeholder: "الاسم الاول للعميل",
    },
    {
      type: "multiSelect",

      name: "area_id",
      value: "id",
      label: "name",
      placeholder: "المنطقة",
    },
    {
      type: "dropdown",
      name: "branch_id",
      value: "id",
      label: "name",
      placeholder: "الفرع",
    },

    {
      type: "dropdown",
      name: "paymenttype_id",
      value: "id",
      label: "name",
      placeholder: "وسيلة الدفع",
    },
    {
      type: "dropdown",
      name: "delivery_id",
      value: "id",
      label: "name",
      placeholder: "الموصل",
    },
    {
      type: "multiSelect",
      name: "order_type_id",
      value: "id",
      label: "name",
      placeholder: "النوع",
      options: [
        { id: 0, name: "توصيل" },
        { id: 1, name: "استلام من المطعم" },
      ],
    },
    {
      type: "multiSelect",
      name: "order_status_id",
      value: "id",
      label: "name",
      placeholder: "الحالة",
    },
    {
      type: "dropdown",
      name: "platform_id",
      value: "id",
      label: "name",
      placeholder: "البلاتفورم",
    },
    {
      type: "date",
      name: "date_from",
      placeholder: "تاريخ من",
      ignoreStartDate: false,
    },
    {
      type: "date",
      name: "date_to",
      placeholder: "تاريخ الى",
      ignoreStartDate: false,
    },
  ];
  selected_user_id: any;
  branchesId: string = "";

  constructor(
    private helper: OrdersHelper,
    private branche: BranchesService,
    private payment: PaymentsService,
    private statue: OrdersService,
    private generic: GenericService,
    private graphQLService: GraphQLService,
    private notificationsService: NotificationsService,
    private townsService: TownsService,
    private deliversService: DeliversService,
    private platformsService: PlatformsService,
    private ActivatedRoute: ActivatedRoute,
    private orderStatusService: OrderStatusService
  ) {}

  ngOnInit() {
    this.allData();
    this.branch_id = this.ActivatedRoute.snapshot.params?.id;
    if (this.branch_id) {
      this.filterInputs[3].disabled = true;
    }
  }

  getDelivers(): void {
    this.deliversService.getForDropdown().subscribe({
      next: (response: any) => {
        this.filterInputs[5].options = response.data.delivers;
      },
      error: (error) => {},
    });
  }

  getUsersSuggestions(name) {
    console.log(name, "name");
    clearTimeout(this.timer);
    this.selected_user_id = undefined;

    if (name.id) {
      this.selected_user_id = name?.id;
    } else if (name) {
      let names = (name as string).split(" ");
      let first_name = names[0] || "";
      let last_name = names[1] || "";
      this.timer = setTimeout(() => {
        this.graphQLService
          .query(
            gql`
          {
            billingAddress(first_nameLike:"%${first_name}%",last_nameLike:"%${last_name}%"){
              id
              first_name
              last_name
              user_id
            }
          }
          `
          )
          .subscribe({
            next: (response: any) => {
              this.filterInputs[1].options = response.data.billingAddress;
            },
            error: (error) => {},
          });
      }, 1000);
    }
  }
  getDeliveries(branch_id: number) {
    this.graphQLService
      .query(
        gql`
          {
            delivers(
              vendor_idEq: ${this.RestaurantId}
              branch_idEq : ${branch_id}) {
              id
              vendor_id
              name
            }
          }
        `
      )
      .subscribe({
        next: (Response: any) => {
          this.deliveries = Response.data.delivers;
        },
      });
  }
  async allData() {
    const data: any = await Promise.all([
      this.branche.getRestaurant(this.RestaurantId).toPromise(),
      this.townsService.get(),
      this.payment.get(),
      // this.userService.getuserSalesDropdown({}),
    ]);

    this.filterInputs[3].options = data[0];
    this.filterInputs[2].options = data[1];
    this.filterInputs[4].options = data[2];
    // this.users = data[4];

    (data[0] as Array<any>).forEach((branch: any, index) => {
      this.branchesId += branch.id.toString();
      if (data[0].length != index + 1) {
        this.branchesId += ",";
      }
    });
    this.getDelivers();
    this.getPlatforms();
    this.getOrderStatusSorted();
    this.get();
  }
  getOrderStatusSorted() {
    this.orderStatusService.getSorted().subscribe({
      next: (response: any) => {
        this.status = response.data.orderStatus;
        this.filterInputs[7].options = response.data.orderStatus;
      },
      error: (error) => {},
    });
  }

  getPlatforms(): void {
    this.platformsService.getDropDown().subscribe({
      next: (response: any) => {
        this.filterInputs[8].options = response.data.platforms;
      },
      error: (error) => {},
    });
  }
  filter(values: any) {
    this.filterData = `
      orderByDesc: id`;
    if (this.branch_id) {
      this.filterData += `
        branch_id : ${this.branch_id}
      `;
    }
    Object.keys(values).forEach((key) => {
      let value: any = values[key];
      if (key == "date_from") {
        this.filterData += `
            DateFilter: ["${values.date_from}", "${values.date_to}","created"]
            `;
        delete values.date_to;
        delete values.date_from;
      } else if (["order_status_id", "order_type_id"].includes(key)) {
        this.filterData += `
          ${key}In: ${JSON.stringify(values[key])}
          `;
      } else if (key == "area_id") {
        this.filterData += `
          ${key}In: ${JSON.stringify(values["area_id"])}
          `;
      } else if (key == "billing_address_id" && this.selected_user_id) {
        this.filterData += `
          ${key}Eq: ${this.selected_user_id}
          `;
      } else if (value != undefined) {
        this.filterData += `
          ${key}Eq: ${value}
          `;
      }
    });

    if (!this.filterData.includes("order_type_id")) {
      this.filterData += `
        order_type_idNotEq : 2
      `;
    }
    this.page = 1;
    this.get();
  }

  get() {
    if (this.branchesId) {
      if (!this.filterData.includes("branch_id")) {
        this.filterData += `
        branch_idIn: [${this.branchesId}]`;
      }
      this.graphQLService
        .getData("orders", ORDERSdATA, this.page, this.limit, this.filterData)
        .subscribe({
          next: (response: any) => {
            this.data = response.data.orders;
            this.calc();
          },
          error: (error) => {},
        });
    }
  }

  calc() {
    if (this.data) {
      this.total = this.data.results
        .filter((d) => d.order_status_id == 4)
        .map((d) => d.total)
        .reduce((accumolator, value) => accumolator + value, 0);
      this.cancel = this.data.results
        .filter(
          (d) =>
            d.order_status_id == 5 ||
            d.order_status_id == 7 ||
            d.order_status_id == 6 ||
            d.order_status_id == 8
        )
        .map((d) => d.total)
        .reduce((accumolator, value) => accumolator + value, 0);
      this.commission = this.data.results
        .filter(
          (d) =>
            d.order_status_id == 1 ||
            d.order_status_id == 2 ||
            d.order_status_id == 3
        )
        .map((d) => d.total)
        .reduce((accumolator, value) => accumolator + value, 0);
    }
  }

  showAdress(adress, event) {
    event.stopPropagation();
    this.billingData = adress;
    this.isBillingVisible = true;
  }

  getOrderDetails(details: any[], adress, user, index?) {
    details.length == 0
      ? (this.details = [])
      : (this.details = this.helper.createDetails(details));
    if (!adress) {
      this.billingData = user;
    } else {
      this.billingData = adress;
    }

    this.OrderId = index != null ? this.data.results[index].id : this.OrderId;
    this.index = index != null ? index : 0;
    this.isVisible = true;
  }

  successOperation() {
    this.isVisible = false;
  }

  onPageChange(page) {
    this.page = page;
    this.get();
  }

  onSizeChange(limit) {
    this.page = 1;
    this.limit = limit;
    this.get();
  }

  changeOrderStatus(order: any): void {
    //select delivery or not
    if (
      order.order_status.id == 2 &&
      !this.selectdeliveryForOrderModal &&
      order.order_type_id == 0
    ) {
      this.getDeliveries(order.branch_id);
      this.selectdeliveryForOrderModal = true;
      this.selectedOrder = order;
      return;
    }
    if (this.delivery_id) {
      order.delivery_id = this.delivery_id;
    }
    if (order.order_type_id != 0 && order.order_status.id == 2) {
      order.order_status_id = 4;
    }else{
      //get next order status name      order status sorted by sort key
      order.order_status_id = this.status[order.order_status.sort + 1].id;
    }

    this.editOrder(order);
  }

  cancelOrder(order: any): void {
    order.order_status_id = 8;
    this.editOrder(order);
  }

  editOrder(order): void {
    try {
      this.statue
        .editOrder(order.id, {
          order_status_id: order.order_status_id,
          delivery_id: order.delivery_id,
        })
        .then((data) => {
          if (order?.users?.id) {
            this.sendNotification(
              this.status[order?.order_status?.id + 1]?.name,
              order?.users?.id,
              order.id
            );
          }
          this.onSuccessEditOrder(
            order.order_status.id == 8
              ? "تم الغاء الطلب بنجاح"
              : "تمت تغيير حالة الطلب بنجاح"
          );
        });
    } catch {
      this.onFailEditOrder();
    }
  }

  onSuccessEditOrder(message): void {
    this.generic.showNotification("success", "حالة الطلب", message);
    this.get();

    this.hideSelectDeliveryForOrderModal();
  }

  onFailEditOrder(): void {
    this.generic.showNotification("error", "حالة الطلب", "حدث خطأ ما");
  }

  hideSelectDeliveryForOrderModal(): void {
    this.selectdeliveryForOrderModal = false;
    this.selectedDelivery = undefined;
    this.deliveries = [];
    this.delivery_id = undefined;
  }

  currentOrderStatusName(order): string {
    return order?.order_type_id != 0 && order?.order_status?.id == 2
      ? "تم التسليم"
      : this.status[order?.order_status?.sort + 1]?.name;
  }

  sendNotification(status, user_id, order_id) {
    try {
      const response = this.notificationsService.sendToDevice(
        ` حالة الطلب رقم ${order_id}`,
        status,
        user_id,
        undefined
      );
    } catch (error) {}
  }

  edit(order: any) {
    console.log(order);

    // if ([7, 8, 5].includes(order.order_status_id)) {
    //   this.generic.showNotification(
    //     "error",
    //     "تعديل الطلب",
    //     "لا يمكن تعديل طلب ملغى"
    //   );
    // } else {
    localStorage.setItem("order_id", order.id);
    this.editOrderVisiblity = true;
    // }
  }

  closeEditOrderModal() {
    this.editOrderVisiblity = false;
    this.get();
  }

  handleFilterDropdownChange(values) {
    console.log(values);

    let key = Object.keys(values)[0];
    switch (Object.keys(values)[0]) {
      case "billing_address_id":
        this.getUsersSuggestions(values[key]);
        break;
    }
  }
}

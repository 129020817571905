<form [formGroup]="orderForm">
  <div class="bg-white1 row px-1 py-3 mt-2">
    <div class="col-md-12 mb-4">
      <h2 class="m-0 text-black-alpha-90">بيانات العميل</h2>
    </div>
    <div class="field col-md-4">
      <span class="p-float-label">
        <!-- <p-dropdown
          [options]="users"
          formControlName="user_id"
          (ngModelChange)="selectUser()"
          [filter]="true"
          [showClear]="true"
          [autoDisplayFirst]="false"
          filterBy="username"
          optionLabel="username"
          optionValue="id"
          styleClass="w-100"
          inputId="user_id"
        ></p-dropdown> -->
        <p-autoComplete
          [(ngModel)]="userInput"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="getClientsSuggestions($event)"
          [suggestions]="userOptions"
          [field]="'username'"
          placeholder="رقم الموبيل"
          styleClass="w-100"
        >
        </p-autoComplete>
        <!-- <label for="user_id">اسم المستخدم</label> -->
      </span>
    </div>
    <div class="col-md-4 d-flex align-items-center">
      <button class="btn btn-info" (click)="showAddUserModal('add')">
        إضافة
      </button>
      <button
        class="btn btn-success"
        (click)="showAddUserModal('edit')"
        *ngIf="selectedUser"
      >
        تعديل
      </button>
    </div>
    <div class="col-12" *ngIf="selectedUser">
      <div class="row py-2">
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">الاسم :</p>
          <p class="m-0 text-black-alpha-90">{{ selectedUser?.name }}</p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">اسم المستخدم :</p>
          <p class="m-0 text-black-alpha-90">{{ selectedUser?.username }}</p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">الايميل :</p>
          <p class="m-0 text-black-alpha-90">{{ selectedUser?.email }}</p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">رقم التلفون :</p>
          <p class="m-0 text-black-alpha-90">{{ selectedUser?.mobile }}</p>
        </div>
      </div>
    </div>
  </div>
</form>

<p-dialog
  header="أضف عميل"
  [(visible)]="addUserModalVisibility"
  [styleClass]="'w-md-50 w-100'"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="hideAddUserModal()"
>
  <form [formGroup]="userForm">
    <div class="row py-3">
      <div class="field col-12 px-2">
        <span class="p-float-label">
          <input
            id="name"
            type="text"
            pInputText
            formControlName="name"
            class="w-100"
          />
          <label for="name">الاسم</label>
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label">
          <input
            id="first_name"
            type="text"
            pInputText
            formControlName="first_name"
            class="w-100"
          />
          <label for="first_name">الاسم الأول</label>
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label">
          <input
            id="last_name"
            type="text"
            pInputText
            formControlName="last_name"
            class="w-100"
          />
          <label for="last_name">الاسم الأخير</label>
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label">
          <input
            id="username"
            type="text"
            pInputText
            formControlName="username"
            class="w-100"
            (blur)="validateUniq('username')"
          />
          <label for="username">اسم المستخدم</label>
        </span>
        <span
          *ngIf="isUsed.username && userForm.get('username').value"
          class="s-float text-danger"
        >
          هذا الاسم مستخدم
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label" *ngIf="!isEditUser">
          <input
            id="password"
            type="text"
            pInputText
            formControlName="password"
            class="w-100"
            disabled
          />
          <label for="password">كلمة السر</label>
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label">
          <input
            id="email"
            type="text"
            pInputText
            formControlName="email"
            class="w-100"
            (blur)="validateUniq('email')"
          />
          <label for="email">الايميل</label>
        </span>
        <span
          *ngIf="isUsed.email && userForm.get('email').value"
          class="s-float text-danger"
        >
          هذا الإيميل مستخدم من قبل
        </span>
      </div>
      <div class="field col-6 px-2">
        <span class="p-float-label">
          <input
            id="mobile"
            type="text"
            pInputText
            formControlName="mobile"
            class="w-100"
            (blur)="validateUniq('mobile')"
          />
          <label for="mobile">رقم الهاتف</label>
        </span>
        <span
          *ngIf="isUsed.mobile && userForm.get('mobile').value"
          class="s-float text-danger"
        >
          هذا الرقم مستخدم من قبل
        </span>
      </div>
      <!-- <div class="field col-md-5 col-6 px-2">
        <span class="p-float-label">
          <p-dropdown
            [options]="branches"
            formControlName="branch_id"
            optionLabel="name"
            optionValue="id"
            styleClass="w-100"
            inputId="branch_id"
            [showClear]="true"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label for="branch_id">الفرع</label>
        </span>
      </div> -->
      <div class="field col-md-7 col-12 px-2">
        <span class="p-float-label">
          <input
            id="address"
            type="text"
            pInputText
            class="w-100"
            formControlName="address"
          />
          <label for="address">العنوان</label>
        </span>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-info w-75"
          (click)="isEditUser ? editUser() : addUser()"
          [disabled]="
            userForm.invalid || isUsed.username || isUsed.email || isUsed.mobile
          "
        >
          {{ isEditUser ? "تعديل" : "أضف" }}
        </button>
      </div>
    </div>
  </form>
</p-dialog>

export const ORDERSdATA = `
    id
    total
    branch_id
    hall_service
    user_id
    order_status_id
    taxes
    notes
    discount
    commission
    area {
      name
    }
    delivery_id
    delivery_serivce
    delivery_time
    takeway
    canceled_reason
    CancelReasons {
      name
    }
    order_type_id
    delivery_comment
    offer_discount
    wallet_discount
    rated
    area_id
    sub_total
    voucher_discount
    created_by
    modified_by
    created
    modified
    order_details {
        id
        notes
        amount
        created
        order_details_options {
          menu_options {name}
        }
        total
        menu_categories_items{
            name
            price
            created
        }
    }
    platforms{
        id
        name
        name_en
    }
    branches {
        id
        name
        name_en
        address
        phone
        phone_two
        latitude
        longitude
        created
        modified
        status
        user_id
        activated
        address_en
        status_id
        code
        created_by
        modified_by
        restaurant{
            name
            logo
        }
    }
    users {
        id
        user_group_id
        email
        mobile
        address
        branch_id
        vendor_id
        name
        first_name
        last_name
        username
    }
    billing_address {
        id
        user_id
        first_name
        last_name
        address
        area_id
        floor_number
        apartment_number
        phone
        created_at
        updated_at
        areas {name}
        notes
        latitude
        longitude
        building_number
        default_address
    }
    order_status {
        id
        name
        name_en
        sort
    }
    paymenttype {
        id
        name
        name_en
    }
    drivers{
        id
        name
    }
    platforms{
        id
        name
    }
    table {
        number
    }
    waiter {
        name
        first_name
        last_name
    }

`

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GraphQLService } from "../graph/graphql.service";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class OrderStatusService {
  constructor(
    private graphQLService: GraphQLService
  ) {}

  getSorted() {
    return this.graphQLService.query(gql`
      {
        orderStatus(orderBy: sort) {
          id
          name
          name_en
          sort
        }
      }
    `);
  }
}

import { Routes } from "@angular/router";
import { CanAccessGuardService } from "src/core/guard/can-access.service";
import { AddIndexEditDeleteModule } from "./main/addIndexEditDelete/add-index-edit-delete.module";
import { FullComponent } from "./layouts/full/full.component";
import { FinanceReportModule } from "./main/finance-reports/finance-report.module";
import { MyVendorsModule } from "./main/my-vendors/my-vendors.module";
import { NotificationsModule } from "./main/marketing/notifications/notifications.module";
import { RestuarantsDetailsModule } from "./main/restaurants/components/restuarants-details/restuarants-details.module";
import { PrintComponent } from "src/core/shared/print-order/print.component";

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "",

        redirectTo: "/authentication/login",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        canActivateChild: [CanAccessGuardService],

        loadChildren: () =>
          import("src/app/main/dashboards/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "new-orders",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("./main/orders/components/new-orders/new-orders.module").then(
            (m) => m.NewOrdersModule
          ),
      },
      {
        path: "orders",
        loadChildren: () =>
          import("./main/orders/order.module").then((m) => m.OrdersModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "attributes",
        loadChildren: () =>
          import("./main/attributes/attributes.module").then(
            (m) => m.AttributesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "branches",
        loadChildren: () =>
          import("./main/branches/branches.module").then(
            (m) => m.BranchesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staticpages",
        loadChildren: () =>
          import("./main/marketing/static-pages/static-pages.module").then(
            (m) => m.StaticPagesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./main/marketing/notifications/notifications.module").then(
            (m) => NotificationsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restuarantDetails",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restuarants-details/restuarants-details.module"
          ).then((m) => RestuarantsDetailsModule),
      },
      {
        path: "faq",
        loadChildren: () =>
          import("./main/marketing/faq/faq.module").then((m) => m.FaqModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RestaurantAttachmentTypes",
        loadChildren: () =>
          import(
            "./main/restaurants/components/RestaurantAttachmentTypes/attachment-type.module"
          ).then((m) => m.AttachmentTypesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "orders-admin",
        loadChildren: () =>
          import(
            "./main/orders/components/orders-admin/orders-admin.module"
          ).then((m) => m.OrdersAdminModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "orders-hall",
        loadChildren: () =>
          import(
            "./main/orders/components/orders-hall/orders-hall.module"
          ).then((m) => m.OrdersHallModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restaurants",
        loadChildren: () =>
          import("./main/restaurants/restaurants.module").then(
            (m) => m.RestaurantsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "WalletMerchant",
        loadChildren: () =>
          import("./main/wallet-merchant/wallet-merchant.module").then(
            (m) => m.WalletMerchantModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "Complaints",
        loadChildren: () =>
          import("./main/settings/complaints/complaints.module").then(
            (m) => m.ComplaintsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RestaurantStatus",
        loadChildren: () =>
          import(
            "./main/restaurants/components/RestaurantStatus/restaurant-status.module"
          ).then((m) => m.RestaurantStatusModule),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "information",
        loadChildren: () =>
          import("./main/settings/information/information.module").then(
            (m) => m.InformationModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "delivers",
        loadChildren: () =>
          import(
            "./main/delivery department/components/delivers/delivers.module"
          ).then((m) => m.DeliverssModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "changePassword",
        loadChildren: () =>
          import("src/app/layouts/changePassword/changePassword.module").then(
            (m) => m.ChangePasswordModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "deliveryDepartment",
        loadChildren: () =>
          import("./main/delivery department/deliveryDepartment.module").then(
            (m) => m.deliveryDepartmentModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "deliversOrdersCanceled",
        loadChildren: () =>
          import(
            "./main/delivery department/components/deliversOrdersCanceled/delivers-orders-caceled.module"
          ).then((m) => m.DeliversOrdersCaceledModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restaurantApprovalAssginemt",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restaurantApprovalAssginemt/restaurant-approval-assginemt.module"
          ).then((m) => m.RestaurantApprovalAssginemtModule),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "WalletSetting",
        loadChildren: () =>
          import("./main/settings/WalletSettings/WalletSettings.module").then(
            (m) => m.WalletsSettingModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "Points",
        loadChildren: () =>
          import("./main/marketing/Points/point.module").then(
            (m) => m.PointsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "PointsSetting",
        loadChildren: () =>
          import("./main/settings/pointSettings/pointSettings.module").then(
            (m) => m.PointSettingsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "rest-attachment",
        loadChildren: () =>
          import(
            "./main/restaurants/components/rest-attachment/rest.module"
          ).then((m) => m.RestModule),
      },
      {
        path: "Wallets",
        loadChildren: () =>
          import("./main/marketing/Wallets/Wallets.module").then(
            (m) => m.WalletsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "vouchers",
        loadChildren: () =>
          import("./main/marketing/Vouchers/vouchers.module").then(
            (m) => m.VoucherModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "menu",
        loadChildren: () =>
          import("./main/min-menu/min-menu.module").then(
            (m) => m.MinMenuModule
          ),
      },
      {
        path: "mainmenu",
        loadChildren: () =>
          import("./main/mainmenu/mainmenu.module").then(
            (m) => m.MainmenuModule
          ),
      },
      {
        path: "worketimes",
        loadChildren: () =>
          import(
            "./main/restaurants/components/working-times/working-times.module"
          ).then((m) => m.WorkingTimesModule),
        canActivateChild: [CanAccessGuardService],
      },
      // {
      //   path: "branchOrders",
      //   loadChildren: () =>
      //     import(
      //       "./main/branches/components/branch_orders/branch_orders.module"
      //     ).then((m) => m.BranchOrdersModule),
      // },
      {
        path: "restaurant-cuisine",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restaurant-cuisines/restaurant-cuisines.module"
          ).then((m) => m.RestaurantCuisinesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "brancheszones",
        loadChildren: () =>
          import(
            "./main/branches/components/branches-zones/branches-zones.module"
          ).then((m) => m.BranchesZonesModule),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "offers",
        loadChildren: () =>
          import("./main/marketing/offers/offers.module").then(
            (m) => m.OffersModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "slider",
        loadChildren: () =>
          import("./main/marketing/sliders/sliders.module").then(
            (m) => m.SlidersModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "welcome",
        loadChildren: () =>
          import("./layouts/welcome/welcome.module").then(
            (m) => m.WelcomeModule
          ),
      },
      {
        path: "UserCopouns",
        loadChildren: () =>
          import("./main/marketing/UserCopouns/user-copouns.module").then(
            (m) => m.UserCopounsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./main/users/users.module").then((m) => m.UsersModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "products",
        loadChildren: () =>
          import("./main/settings/products/products.module").then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: "products-categories",
        loadChildren: () =>
          import(
            "./main/settings/products-categories/products-categories.module"
          ).then((m) => m.ProductsCategoriesModule),
      },
      {
        path: "units",
        loadChildren: () =>
          import("./main/settings/units/units.module").then(
            (m) => m.UnitsModule
          ),
      },
      {
        path: "CustomerSupport",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/settings/customer-support/customer-support.module"
          ).then((m) => m.CustomerSupportModule),
      },
      {
        path: "CustomerSupportCategory",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/settings/customer-support-category/customer-support-category.module"
          ).then((m) => m.CustomerSupportCategoryModule),
      },

      {
        path: "finance-report",
        loadChildren: () =>
          import("./main/finance-reports/finance-report.module").then(
            (m) => FinanceReportModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      // {
      //   path: "AddIndexEditDelete",
      //   loadChildren: () =>
      //     import("./main/addIndexEditDelete/add-index-edit-delete.module").then(
      //       (m) => AddIndexEditDeleteModule
      //     ),
      //   canActivateChild: [CanAccessGuardService],
      // },

      {
        path: "myvendors",
        loadChildren: () =>
          import("./main/my-vendors/my-vendors.module").then(
            (m) => MyVendorsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "menu-approval",
        loadChildren: () =>
          import("./main/menu-approval/menu-approval.module").then(
            (m) => m.MenuApprovalsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "vendor-total-finance",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/vendor-total-finance/restaurant-total-finance.module"
          ).then((m) => m.RestaurantTotalFinanceModule),
      },
      {
        path: "ordersforuser",
        loadChildren: () =>
          import(
            "./main/orders/components/orders-for-user/orders-for-user.module"
          ).then((m) => m.OrdersForUserModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "metatags",
        loadChildren: () =>
          import("./main/settings/meta-tags/meta-tags.module").then(
            (m) => m.MetaTagsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "dishCost",
        loadChildren: () =>
          import("./main/dishes/DishCost/DishCost.module").then(
            (m) => m.DishCostModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "Dishes",
        loadChildren: () =>
          import("./main/reports/Dishes/Dishes.module").then(
            (m) => m.DishesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staffPayments",
        loadChildren: () =>
          import("./main/staff/staffPayments/staffPayments.module").then(
            (m) => m.staffPaymentsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "store",
        loadChildren: () =>
          import("./main/reports/Store/store.module").then(
            (m) => m.StoreModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "suppliers",
        loadChildren: () =>
          import("./main/suppliers/suppliers.module").then(
            (m) => m.suppliersModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "expenses",
        loadChildren: () =>
          import("./main/expenses/expenses/expenses.module").then(
            (m) => m.expensesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "expenses-categories",
        loadChildren: () =>
          import("./main/expenses/expenses-categories/expenses-categories.module").then(
            (m) => m.ExpensesCategoriesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staffs",
        loadChildren: () =>
          import("./main/staff/staffs/staffs.module").then(
            (m) => m.staffsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "popups",
        loadChildren: () =>
          import("./main/marketing/pop-ups/popups.module").then((m) => m.PopupsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staffsCategoery",
        loadChildren: () =>
          import("./main/staff/staffsCategoery/staffsCategoery.module").then(
            (m) => m.staffsCategoeryModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "ItemStore",
        loadChildren: () =>
          import("./main/reports/ItemStore/ItemStore.module").then(
            (m) => m.ItemStoreModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "ItemStoreReport",
        loadChildren: () =>
          import("./main/reports/ItemStoreReport/ItemStoreReport.module").then(
            (m) => m.ItemStoreReportModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "DailyDishesSalesReport",
        loadChildren: () =>
          import(
            "./main/reports/daily-dishes-sales-report/daily-dishes-sales-report.module"
          ).then((m) => m.DailyDishesSalesReportModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staffSalaries",
        loadChildren: () =>
          import("./main/staff/staffsSalaries/staffsSalaries.module").then(
            (m) => m.staffsSalariesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "user-department",
        loadChildren: () =>
          import(
            "./main/settings/user-departments/users-department.module"
          ).then((m) => m.UserDepartmentModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "hometitle",
        loadChildren: () =>
          import("./main/settings/hometitle/hometitle.module").then(
            (m) => m.MobileHeaderModule
          ),
      },
      {
        path: "DishesSalesReport",
        loadChildren: () =>
          import(
            "./main/reports/dishes-sales-report/dishes-sales-report.module"
          ).then((m) => m.DishesSalesReportModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "socialUrls",
        loadChildren: () =>
          import("./main/settings/social-urls/social-urls.module").then(
            (m) => m.SocialUrlsModule
          ),
      },
      {
        path: "NewsSubscribers",
        loadChildren: () =>
          import(
            "./main/marketing/news-subscribers/news-subscribers.module"
          ).then((m) => m.NewsSubscribersModule),
      },
      {
        path: "rates",
        loadChildren: () =>
          import(
            "./main/settings/rates/rates.module"
          ).then((m) => m.ratesModule),
      },
      {
        path: "newsletter",
        loadChildren: () =>
          import(
            "./main/settings/meta-tags copy/newsletter.module"
          ).then((m) => m.newsletterModule),
      },
      {
        path: "daily-report",
        loadChildren: () =>
          import(
            "./main/reports/daily-report/daily-report-index.module"
          ).then((m) => m.DailyReportModule),
      },
      {
        path: "paymob",
        loadChildren: () =>
          import(
            "./main/paymob/paymob.module"
          ).then((m) => m.paymobModule),
      },
      {
        path: "cancelreason",
        loadChildren: () =>
          import(
            "./main/cancelReason/cancelreason.module"
          ).then((m) => m.cancelreasonModule),
      },
      {
        path: "BillingAddress",
        loadChildren: () =>
          import(
            "./main/billingAddress/billingAddress.module"
          ).then((m) => m.BillingAddressModule),
      },
    ],
  },

  {
    path: "orderReceipt/:id",
    component : PrintComponent
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./main/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];

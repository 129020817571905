<app-order-form-main-info
  [orderForm]="orderForm"
  [branches]="branches"
  [paymentTypes]="paymentTypes"
  [orderTypes]="orderTypes"
  [towns]="towns"
  [orderStatus]="orderStatus"
  [isOrderEdit]="isOrderEdit"
></app-order-form-main-info>

<ng-container *ngIf="[0, 1].includes(orderForm.get('order_type_id').value)">
  <app-order-form-client-info
    [orderForm]="orderForm"
    [users]="users"
    [branches]="branches"
    [selectedUser]="selectedUser"
    (selectUser)="selectUser()"
    (getUsers)="getUsers()"
    [isOrderEdit]="isOrderEdit"
  ></app-order-form-client-info>
</ng-container>

<ng-container *ngIf="selectedUser && orderForm.get('order_type_id').value == 0">
  <app-order-form-billiing-address
    [orderForm]="orderForm"
    [billingAddresses]="billingAddresses"
    [selectedUser]="selectedUser"
    [selectedBillingAddress]="selectedBillingAddress"
    [towns]="towns"
    (selectBillingAddress)="selectBillingAddress()"
    (getBillingAddresses)="getBillingAddresses()"
  ></app-order-form-billiing-address>
</ng-container>

<div class="my-2">
  <h5 class="text-danger" *ngIf="isOrderEdit">
    ملحوظة : أى تعديل على الأطباق تتم مباشرة وليس عند الضغط على زر تعديل الطلب
  </h5>
  <h5
    class="text-danger"
    *ngIf="
      orderForm.controls['order_type_id'].value == 0 &&
      !orderForm.controls['billing_address_id'].value
    "
  >
    ملحوظة : لن تظهر الاطباق لعدم تحديد عنوان التوصيل ونوع الطلب توصيل
  </h5>
  <button class="btn btn-info mb-2" (click)="showAddItemModal()">
    أضف طبق
  </button>
  <div class="table-responsive custom-br custom-shadow">
    <nz-table
      [nzFrontPagination]="false"
      [nzData]="orderDetails"
      class="table forms-table table-striped"
      [nzScroll]="{ x: '240px' }"
      *ngIf="
        orderDetails.length &&
        (orderForm.controls['order_type_id'].value == 0
          ? orderForm.controls['billing_address_id'].value
          : true)
      "
    >
      <thead>
        <tr class="bg-light">
          <th *ngFor="let head of heads">
            <p class="text-nowrap">
              {{ head }}
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let orderDetail of orderDetails; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ orderDetailsData[i].menuCategoryName }}</td>
          <td>
            {{ orderDetailsData[i].item.name }}
          </td>

          <td>
            {{ orderDetail.total / orderDetail?.amount }}
          </td>
          <td>{{ orderDetail?.amount }}</td>
          <td>{{ orderDetail.total }}</td>
          <td>
            <div class="d-flex justify-content-center gap-2">
              <button
                class="btn btn-info"
                (click)="editItem(orderDetail, orderDetailsData[i], i)"
              >
                <i nz-icon nzType="edit" nzTheme="outline"></i>
              </button>
              <button
                nz-popconfirm
                nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
                nzPopconfirmPlacement="top"
                (nzOnConfirm)="deleteOrderDetail(i, orderDetail)"
                class="btn btn-danger"
              >
                <i nz-icon nzType="delete" nzTheme="outline"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div
  class="row bg-white1 text-black-alpha-90 px-1 py-3"
  *ngIf="
    orderDetails.length &&
    (orderForm.controls['order_type_id'].value == 0
      ? orderForm.controls['billing_address_id'].value
      : true)
  "
>
  <div class="col-md-12 d-flex flex-column gap-2 my-2">
    <h4>الملاحظات</h4>
    <textarea
      rows="5"
      cols="20"
      pInputTextarea
      [autoResize]="true"
      [(ngModel)]="orderNotes"
    ></textarea>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <h4 class="m-0">الحساب الجزئى</h4>
        <p class="m-0">
          {{ subTotal }}
        </p>
      </div>
      <div class="col-12 d-flex justify-content-between">
        <h4 class="m-0">الضريبة</h4>
        <p class="m-0">{{ taxes }} %</p>
      </div>
      <div
        class="col-12 d-flex justify-content-between"
        *ngIf="orderForm.get('order_type_id').value == 0"
      >
        <h4 class="m-0">تكلفة التوصيل</h4>
        <p class="m-0">
          {{ delivery_cost }}
        </p>
      </div>
      <div class="col-12 d-flex justify-content-between">
        <h4 class="m-0">الحساب الكلى</h4>
        <p class="m-0">
          {{ total }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <button
      class="btn btn-info w-50"
      (click)="isOrderEdit ? editOrder() : order()"
    >
      {{ isOrderEdit ? "تعديل الطلب" : "أطلب" }}
    </button>
  </div>
</div>

<p-dialog
  header="أضف طبق"
  [(visible)]="addItemModalIsVisible"
  [styleClass]="tabIndex == 3 ? 'w-400px' : 'w-75'"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="hideAddItemModal()"
>
  <button
    class="btn btn-info mb-2"
    (click)="back()"
    *ngIf="tabIndex > 1 && isItemEdit == false"
  >
    الرجوع
  </button>
  <!-- menu categories -->
  <div class="row" *ngIf="tabIndex == 1">
    <div class="col-md-4 p-2" *ngFor="let menuCategory of menuCategories">
      <p-card [styleClass]="'bg-bluegray-50'">
        <img
          [src]="imgSrc + menuCategory.photo"
          alt=""
          class="w-100"
          (click)="selectCategory(menuCategory)"
        />
        <h3 class="mt-2">{{ menuCategory.name }}</h3>
      </p-card>
    </div>
  </div>
  <!-- menu items -->
  <div class="row" *ngIf="tabIndex == 2">
    <div
      class="col-md-4 p-2"
      *ngFor="let menuCategoriesItem of menuCategoriesItems"
    >
      <div class="row">
        <div class="col-12">
          <p-card [styleClass]="'bg-bluegray-50'">
            <img
              [src]="imgSrc + menuCategoriesItem.photo"
              alt=""
              class="w-100"
              (click)="selectItem(menuCategoriesItem)"
            />
            <h3 class="mt-2">{{ menuCategoriesItem.name }}</h3>
          </p-card>
        </div>
      </div>
    </div>
  </div>
  <!-- item -->
  <div class="row" *ngIf="tabIndex == 3">
    <div class="col-12">
      <img [src]="imgSrc + selectedItem?.photo" class="w-100" alt="" />
    </div>
    <div class="col-12 d-flex flex-column">
      <h3>{{ selectedItem?.name }}</h3>
      <p>{{ selectedItem?.description }}</p>
      <div class="d-flex gap-2">
        <p class="m-0">السعر :</p>
        <p class="m-0">{{ selectedItem?.price || "على حسب الإختيار" }}</p>
      </div>
      <p-inputNumber
        [(ngModel)]="amount"
        (ngModelChange)="calculateItemTotal()"
        [showButtons]="true"
        [min]="1"
        inputId="stacked"
        styleClass="w-100"
      >
      </p-inputNumber>
      <div class="d-flex gap-2">
        <p class="m-0">الحساب الكلى</p>
        <p>{{ itemTotal }}</p>
      </div>
    </div>
    <div
      class="col-6"
      *ngFor="
        let menu_options_topic of selectedItem?.menu_options_topics;
        let topicIndex = index
      "
    >
      <p-card [styleClass]="'bg-bluegray-50'">
        <div class="row w-100 m-0 bg-white1">
          <div class="col-md-12 m-0 p-0">
            <div class="d-flex justify-content-between p-1">
              <h4>{{ menu_options_topic.name }}</h4>
              <p-tag
                [severity]="
                  menu_options_topic.required == 1 ? 'danger' : 'info'
                "
                [value]="
                  menu_options_topic.required == 1 ? 'اجبارى' : 'اختيارى'
                "
                [rounded]="true"
              ></p-tag>
            </div>
          </div>
          <div class="col-md-12 h-10px">
            <p-divider styleClass="m-0"></p-divider>
          </div>
          <div
            class="col-md-12 d-flex justify-content-between my-1"
            *ngFor="
              let menu_option of menu_options_topic.menu_options;
              let optionIndex = index
            "
          >
            <div class="d-flex gap-2 align-items-center">
              <input
                [type]="
                  menu_options_topic.max_option_checks == 1
                    ? 'radio'
                    : 'checkbox'
                "
                [disabled]="
                  menu_options_topic.max_option_checks > 1 &&
                  menu_options_topic.selectedOptionCount >=
                    menu_options_topic.max_option_checks &&
                  !!menu_option.isChecked != true
                "
                [name]="'option' + menu_options_topic.id"
                (click)="
                  selectOption(
                    menu_option,
                    menu_options_topic,
                    topicIndex,
                    optionIndex,
                    $event
                  )
                "
                [checked]="menu_option.isChecked"
              />
              <p class="m-0">{{ menu_option.name }}</p>
            </div>
            <p class="m-0">
              {{ menu_option.price }}
            </p>
          </div>
        </div>
      </p-card>
    </div>
    <div class="col-md-12 d-flex flex-column gap-2 my-2">
      <h4>الملاحظة</h4>
      <textarea
        rows="5"
        cols="20"
        pInputTextarea
        [autoResize]="true"
        [(ngModel)]="notes"
      ></textarea>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <button
        class="btn btn-info w-50"
        (click)="addToOrderDetails()"
        [disabled]="addToOrderDetailsDisabled"
      >
        <p class="fw-bold m-0">{{ isItemEdit ? "تعديل" : "أضف" }}</p>
      </button>
    </div>
  </div>
</p-dialog>
